import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["regionField", "roles"]
  static values = { items: Array }

  connect() {
    this.toggleRegionField();
  }

  toggleRegionField() {
    const selectedRoles = this.rolesTargets
      .filter(input => input.checked)
      .map(input => input.value);

    if (selectedRoles.some(role => this.itemsValue.includes(role))) {
      this.regionFieldTarget.style.display = "block";
    } else {
      this.regionFieldTarget.style.display = "none";
    }
  }
}
