import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  submitForm(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 300)
  }
}
