import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["required"];

  connect() {
    this.toggleRequired();
  }

  toggleRequired(event) {
    if (event?.target?.checked) {
      this.requiredTarget.classList.remove("hidden");
    } else {
      this.requiredTarget.classList.add("hidden");
    }
  }
}
